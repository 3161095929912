
import pageint from "@/lib/utils/pageint";
import { useRouter } from "next/router";
import React, { useState } from "react";
import ScheduleStateContainer from "../schedule/schedule-state-container";
import SchedulePlaceholder from "../schedule/schedule-placeholder";
import ModalForm from "@/components/templateux/modal/modal-form";
import ScheduleForm from "../schedule/schedule-form";
import toast from 'react-simple-toasts';
import { toastConfig } from 'react-simple-toasts';
import AdminBar from "@/components/templateux/header-bar/admin-bar";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import Link from "next/link";
import { hasData } from "@/lib/utils/hasData";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import SchedulePlaceholderHome from "./schedule-placeholder-home";
import ScheduleStateContainerHome from "./schedule-state-container-home";

export default function ScheduleArrayHome({

   itemdata
  ,itemmeta
  ,placeholderdata={
                        show_name:'Loading...'
                       ,show_description: 'Loading...'
                       ,show_id: 0
                       ,weekday: "0"
                       ,frequency: "daily"
                       ,start_time: "00:00:00"
                       ,end_time: "00:00:00"
                    }
  ,isLoading = false
  ,isError = false
  ,sum_items = 0
  ,mutateItemsDeleteOne
  ,userdata
  ,ownerdata
  ,details_collapsed
  ,display_view
  ,cache
  ,set_cache
  ,slugfilter
  ,show_adminbar
  ,nodata
  ,navtype
  ,helptype
  ,isAdmin
}) {
  

  if (isError) {
    return <div className="px-4">Something went wrong...</div>;
  }





    
 

    return (
    <>

      <ul className={` divide-y w-full 
         divide-gray-700 border-gray-700
      `}>

        {itemdata?.length==0 &&
          <li className="text-center my-10 text-xl" >
            {nodata}
          </li>
        }
        
        {itemdata?.map((e,index) => (
          <ScheduleStateContainerHome
            key={index}
            itemdata={e}
            placeholderdata={placeholderdata}
            userdata={userdata}
            ownerdata={ownerdata}
            placeholder={isLoading}
            details_collapsed={details_collapsed}
            display_view={display_view}
            cache={cache}
            set_cache={set_cache}
            nodata={nodata}
            navtype={navtype}
            helptype={helptype}
            isAdmin={isAdmin}
          />
        ))}
        
      </ul>

      
      </>
    );
  }



